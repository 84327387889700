import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'components/FormStructure/Form'
import validationSchema from './validation-schema'
import TextInput from 'components/hook-form/text-input'
import ButtonVariant from 'components/button-variant/button'
import { AppDispatch, RootState } from 'store'
import { msg } from '@lingui/macro'
import { Trans } from '@lingui/macro'
import SelectInput from 'components/hook-form/select-field'
import { useLingui } from '@lingui/react'
import Alert from 'components/alert/alert'
import { createUserAccount } from 'store/slices/usersAccountsSlice'
import { i18n } from '@lingui/core'

interface props {
    setShowAlert: (showAlert: { type: 'success' | 'error'; message: string } | null) => void
    setShowAddUserModal: (showAddUserModal: boolean) => void
  }
  
  const AddUserForm: React.FC<props> = ({ setShowAlert, setShowAddUserModal }) => {
  const { _ } = useLingui()
  const dispatch = useDispatch<AppDispatch>()

  const [errorMsg, setErrorMsg] = useState<string>('')

  const isLoading = useSelector((state: RootState) => state.signIn.isLoading)

  const defaultValues = {
    name: '',
    email: '',
    account_type: ''
  }

  const onSubmit = async (data: typeof defaultValues) => {
    setErrorMsg('')
    try {
      const result = await dispatch(
        createUserAccount({
            name: data.name,
          email: data.email,
          account_type: data.account_type
        })
      ).unwrap()
      if (result.status === 200) {
        const successMessage = _(msg`تم إضافة المستخدم بنجاح`)
        setShowAddUserModal(false)
        setShowAlert?.({ type: 'success', message: successMessage })
      }
    } catch (error: any) {
      setErrorMsg(i18n._(error.message))
    }
  }

  const accountTypeOptions: { key: string; label: string }[] = [
    { key: 'ithraa', label: _(msg`مستخدم إثراء`) }
  ]

  return (
    <Form schema={validationSchema} onSubmit={onSubmit} defaultValues={defaultValues}>
      {errorMsg && <Alert mainText={errorMsg} onClose={() => setErrorMsg('')} type={'error'} />}
      <TextInput name="name" label={msg`اسم المستخدم`.id} placeholder={msg`اسم المستخدم`.id} />
      <TextInput
        name="email"
        label={msg`البريد الإلكتروني`.id}
        type="email"
        placeholder={msg`Exmple@gmail.com`.id}
        helperText="Enter your email address"
      />
      <SelectInput
        name="account_type"
        label={msg`نوع المستخدم`.id}
        options={accountTypeOptions}
        selectionMode="single"
        // placeholder={msg`نوع المستخدم`.id}
      />
      <div className="flex w-full justify-center">
        <ButtonVariant variant="secondary" type="submit" size="lg" isLoading={isLoading}>
          <p>
            <Trans>إضافة</Trans>
          </p>
        </ButtonVariant>
      </div>
    </Form>
  )
}

export default AddUserForm
