import { Outlet, Routes as ReactRoutes, Route } from 'react-router-dom'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
// import VerifyEmailHash from 'pages/VerifyEmailHash'
// import AppLayout from 'components/layouts/AppLayout/AppLayout'
// import ProtectedRoute from './ProtectedRoute'
import Page404 from 'pages/Page404'
// import AuthGuard from 'guards/auth-guard/auth-guard'
import Home from 'pages/landing/home'
import Blog from 'pages/landing/blog'
import Wrapper from 'pages/landing/wrapper'
// import Container from 'pages/auth/container'
import Layout from 'pages/auth/layout'
import routesConfig, { RouteConfig } from 'pages/auth/routesConfig'
import Login from 'pages/AccountPages/login'
import Registration from 'pages/AccountPages/registration'
import ResetPassword from 'pages/AccountPages/reset-password'
import ForgotPassword from 'pages/AccountPages/forgot-password'
import VerifyEmail from 'pages/AccountPages/verify-email'
import AuthGuard from 'guards/auth-guard/auth-guard'
import PublicGuard from 'guards/public-guard/public-guard'
import SupplementaryFiles from 'pages/landing/supplementary-files'
import UserGuard from 'guards/user-guard/user-guard'
import BlogPodcasts from 'pages/landing/blog-podcasts'
//import routesConfig from './routesConfig'
// import Layout from 'pages/auth/layout'
//import routesConfig from 'pages/auth/routesConfig'
//import routesConfig from './routesConfig'
import { Navigate } from 'react-router-dom'
import PagePublic404 from 'pages/landing/404'

function AppRoutes() {
  const userType = useSelector((state: RootState) =>
    typeof state.auth.userType === 'string' ? state.auth.userType : null
  )


  const renderRoutes = (routes: RouteConfig[], basePath = ''): React.ReactNode[] => {
    return routes.flatMap((route) => {
      const fullPath = `${basePath}${route.path}`.replace(/\/+/g, '/')
      const hasChildren = route.children && route.children.length > 0
      const parentRoute = (
        <Route
          key={fullPath}
          path={fullPath} 
          element={
            <UserGuard user_roles={route.roles}>
              {route.component ? (
                <route.component />
              ) : (
                <Navigate to="/app/home" />
              )}
            </UserGuard>
          }
        />
      )
  
      const childRoutes = hasChildren
        ? renderRoutes(route.children ?? [], fullPath + '/')
        : []
  
      return [parentRoute, ...childRoutes]
    })
  }
  

  return (
    <ReactRoutes>
      <Route
        path="/"
        element={
          <Wrapper>
            <Home />
          </Wrapper>
        }
      />
      <Route
        path="/blog"
        element={
          <Wrapper>
            <Blog />
          </Wrapper>
        }
      />
      <Route
        path="/blog-podcast"
        element={
          <Wrapper>
            <BlogPodcasts />
          </Wrapper>
        }
      />
      {/* <Route
        path="/supplementary-files"
        element={
          <Wrapper>
            <SupplementaryFiles />
          </Wrapper>
        }
      /> */}
      <Route
        path="/login"
        element={
          <PublicGuard>
            <Wrapper footer={false} isScrolled={true}>
              <Login />
            </Wrapper>
          </PublicGuard>
        }
      />
      <Route
        path="/register"
        element={
          <PublicGuard>
            <Wrapper footer={false} isScrolled={true}>
              <Registration />
            </Wrapper>
          </PublicGuard>
        }
      />
      <Route
        path="/reset-password"
        element={
          <PublicGuard>
            <Wrapper footer={false} isScrolled={true}>
              <ResetPassword />
            </Wrapper>
          </PublicGuard>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <PublicGuard>
            <Wrapper footer={false} isScrolled={true}>
              <ForgotPassword />
            </Wrapper>
          </PublicGuard>
        }
      />
      <Route
        path="/auth/verify-email"
        element={
          <PublicGuard>
            <Wrapper footer={false} isScrolled={true}>
              <VerifyEmail />
            </Wrapper>
          </PublicGuard>
        }
      />
      <Route
        path="/app"
        element={
          <Wrapper navbar={false} footer={false}>
            <AuthGuard>
              <Layout userRole={userType ?? ''} />
            </AuthGuard>
          </Wrapper>
        }
      >
        <Route path="" element={<Navigate to="/app/home" />} />
        {renderRoutes(routesConfig)}
        <Route path="*" element={<Page404 />} />
      </Route>
      <Route
        path="*"
        element={
          <Wrapper isScrolled={true}>
            <PagePublic404 />
          </Wrapper>
        }
      />
    </ReactRoutes>
  )
}

export default AppRoutes
