import { getRequestStatusLabel } from 'constants/requestStatus'
import useLanguage from 'hooks/useLanguage'
import React from 'react'
import { ApplicationStatus } from 'store/slices/applicationsSlice'
import Badge from 'components/badge/Badge'

interface Props {
  status: ApplicationStatus
}

const getAppStatusStyles = (status: ApplicationStatus) => {
  switch (status) {
    case 'draft':
      return 'disabled'
    case 'pending':
      return 'information'
    case 'pendingIdel':
      return 'information'
    case 'approved':
      return 'success'
    case 'rejected':
      return 'danger'
    default:
      return 'default'
  }
}

const AppStatus: React.FC<Props> = ({ status }) => {
  const language = useLanguage()

  return (
    <Badge
      variant={getAppStatusStyles(status)}
      placeholder={getRequestStatusLabel(status, language)}
      size="sm"
      shape="rectangular"
    />
  )
}

export default AppStatus
