import CalenderSecondIcon from 'assets/icons/CalendarSecondIcon'
import HomeIcon from 'assets/icons/HomeIcon'
import PersonIcon from 'assets/icons/PersonIcon'
import ActivateRequests from 'assets/icons/ActivateRequests'
import MedalIcon from 'assets/icons/MedalIcon'
import ListIcon from 'assets/icons/ListIcon'
import AppsIcon from 'assets/icons/appsIcon'
import ReportIcon from 'assets/icons/ReportIcon'
import WorkFlowIcon from 'assets/icons/WorkflowIcon'
import FileUserIcon from 'assets/icons/FileUserIcon'
import SettingsIcon from 'assets/icons/SettingsIcon'
import EmptyIcon from 'assets/icons/EmptyIcon'

export const iconMap: { [key: string]: React.FC<{ fill?: string }> } = {
  'home-icon': HomeIcon,
  'person-icon': PersonIcon,
  'competition-icon': CalenderSecondIcon,
  'activate-requests-icon': ActivateRequests,
  'medal-icon': MedalIcon,
  'list-icon': ListIcon,
  'apps-icon': AppsIcon,
  'report-icon': ReportIcon,
  'workflows-icon': WorkFlowIcon,
  'file-user': FileUserIcon,
  'settings-icon': SettingsIcon,
  'empty-icon': EmptyIcon,
}