import React, { useState, useEffect, useRef } from 'react'
import InformationIcon from 'assets/icons/InformationIcon'
import { Tooltip } from 'components/toolTip/Tooltip'
import { Trans } from '@lingui/react'
import { useDevice } from 'pages/landing/context/device-context'

interface LabelProps {
  label: string
  tooltipInfoText?: string
  className?: string
  labelClassName?: string
}

const Label: React.FC<LabelProps> = ({ tooltipInfoText, label, className, labelClassName }) => {
  const isMobile = useDevice()
  const [isOpen, setIsOpen] = useState(false)
  const labelRef = useRef<HTMLDivElement | null>(null)

  const handleTooltipToggle = () => {
    if (isMobile) {
      setIsOpen(!isOpen)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (labelRef.current && !labelRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    if (isMobile && isOpen) {
      document.addEventListener('click', handleClickOutside)
    }

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isMobile, isOpen])

  return (
    <div className="flex gap-1 items-center" ref={labelRef}>
      {tooltipInfoText && (
        <Tooltip
          className={className}
          showArrow={true}
          closeDelay={1000}
          content={tooltipInfoText}
          isOpen={!isMobile ? undefined : isOpen}
        >
          <div
            onClick={handleTooltipToggle}       
            onMouseEnter={!isMobile ? () => setIsOpen(true) : undefined} 
            onMouseLeave={!isMobile ? () => setIsOpen(false) : undefined} 
          >
            <InformationIcon />
          </div>
        </Tooltip>
      )}
      <p className={`font-sub-heading-h6-ar text-natural_icon_normal capitalize ${labelClassName}`}>
        <Trans id={label} />
      </p>
    </div>
  )
}

export default Label
