import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
import { Trans } from '@lingui/macro'

Chart.register(ArcElement, Tooltip, Legend)

interface DoughnutChartProps {
  chartProgress: {
    name: string
    percentage: number
    total_applications: string
  }[]
  totalApplications: number
  layout?: 'vertical' | 'horizontal'
  data: {
    name: string
    progressItems: {
      percentage: number
      name: string
    }[]
  }[]
  prefix?: string
  prefixBg?: string
}

const DoughnutChart: React.FC<DoughnutChartProps> = ({
  layout = 'vertical',
  data,
  chartProgress,
  totalApplications,
  prefix,
  prefixBg
}) => {
  const chartData = {
    labels: data.flatMap((_) => _.progressItems.map((p) => p.name)),
    datasets: [
      {
        data: [...chartProgress.map((_) => _.percentage)],
        backgroundColor: ['#7ecd33', '#209CD6'],
        borderWidth: 0,
        hoverOffset: 0
      }
    ]
  }

  const options = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    }
  }
  const colors = ['secondary_fill_normal_pressed', 'primary']
  return (
    <div
      className={
        layout === 'horizontal' ? 'flex flex-row justify-between' : 'flex flex-col justify-center'
      }
    >
      <div className="relative flex flex-row justify-center flex-1">
        <div className={layout === 'horizontal' ? 'w-1/2' : 'w-1/3'}>
          <Doughnut data={chartData} options={options} />
        </div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center w-1/5 flex flex-col">
            <span className="text-wrap text-text-text-secondary text-xs text-center">
              <Trans>{prefixBg ?? ' مجموع المشاركات'}</Trans>
            </span>
            <span className="text-wrap text-text-text-secondary text-center">
              {totalApplications}
            </span>
          </div>
        </div>
      </div>
      {/* Indicators Section */}
      <div className="mt-4 space-y-2 ">
        {chartProgress.map((indicator, index) => (
          <div key={index} className="flex justify-between items-center p-2 px-14">
            <div className="flex items-center">
              <div className={`w-2 h-2 mr-2 bg-${colors[index]}`} />
              <span className="px-2">{indicator.name}</span>
            </div>
            <span
              className={`text-${colors[index]}`}
            >{`${indicator.total_applications} ${prefix ?? ''} (${Number(indicator.percentage??0).toFixed(2)}%)`}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DoughnutChart
