import React, { createContext, useContext, useState, useEffect } from 'react'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setLayoutDirection } from 'store/slices/layoutSlice'
import arMessages from 'locales/ar/messages.json'
import enMessages from 'locales/en/messages.json'

// Load language messages
i18n.load({
  en: enMessages.messages,
  ar: arMessages.messages
})

const LanguageContext = createContext({
  language: 'ar',
  toggleLanguage: () => {}
})

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch = useDispatch()
  const direction = useSelector((state: RootState) => state.layout.direction)
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'ar')

  useEffect(() => {
    i18n.activate(language)
    localStorage.setItem('language', language)

    const newDirection = language === 'ar' ? 'rtl' : 'ltr'
    dispatch(setLayoutDirection(newDirection))
    document.documentElement.lang = language
    document.documentElement.dir = newDirection
  }, [language, direction])

  const toggleLanguage = () => {
    const newLanguage = language === 'ar' ? 'en' : 'ar'
    const newDirection = newLanguage === 'ar' ? 'rtl' : 'ltr'
    setLanguage(newLanguage)
    dispatch(setLayoutDirection(newDirection))
  }

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      <I18nProvider i18n={i18n}>{children}</I18nProvider>
    </LanguageContext.Provider>
  )
}

export const useLanguage = () => useContext(LanguageContext)
