import DataTable from 'components/data-table/data-table'
import React, { useEffect, useState } from 'react'
import {
  generateTableDataFromIthraaApplications,
  renderTableHeaderTh
} from './tableHeaderGenerator'
import { useLingui } from '@lingui/react'
import LocalizedNameText from 'components/LocalizedNameText'
import { getEducationStageLabel } from 'constants/educationStageOptions'
import { formatDateTime } from 'utils/time'
import { AppDispatch, RootState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { fetchIthraaApplicationStatistics } from 'store/slices/ithraaReports'
import { Application } from 'api/ithraaApisTypes'
export default function IthraaReportsListTable({ searchText }: { searchText?: string }) {
  const dispatch = useDispatch<AppDispatch>()
  const [page, setpage] = useState(1)
  const { i18n } = useLingui()
  const {
    data: reports,
    isLoading,

    pagination
  } = useSelector((state: RootState) => state.ithraaReports)
  const { competitionYear } = useSelector((state: RootState) => state.competitionYearNavbar)
  const fetchReports = () => {
    dispatch(
      fetchIthraaApplicationStatistics({
        competition_year_id: competitionYear?.id,
        page: page
      })
    )
  }
  useEffect(() => {
    fetchReports()
  }, [page, competitionYear])

  const columns = renderTableHeaderTh('dataTabelType' as string, i18n.locale as any)
  const renderCell = (item: any, columnKey: string | number) => {
    if (columnKey == 'student_personal_data') {
      return (
        <div className="flex flex-col gap-1">
          <span>{item[columnKey].first_name + ' ' + item[columnKey].last_name}</span>
          <span className="text-primary_lv2_text_normal">{item[columnKey].email}</span>
          <span
            className={`text-primary_lv2_text_normal
          [unicode-bidi:bidi-override] [direction:ltr] ${
            i18n.locale == 'en' ? 'text-left' : 'text-right'
          }`}
          >
            {item[columnKey].phone}
          </span>
        </div>
      )
    } else if (columnKey == 'region') {
      return (
        <LocalizedNameText
          localizedName={item[columnKey]}
          language={i18n.locale as any}
        ></LocalizedNameText>
      )
    } else if (columnKey == 'submission_date') {
      return <span>{formatDateTime(new Date(item[columnKey]), i18n.locale as any)}</span>
    } else if (columnKey == 'educational_institution_info') {
      return (
        <div className="flex flex-col gap-1">
          <span>
            {getEducationStageLabel(item[columnKey]?.education_stage, i18n.locale as any)}
          </span>
          <span className="text-primary_lv2_text_normal">
            <LocalizedNameText
              localizedName={
                item[columnKey]?.[
                  item[columnKey]?.education_stage == 'general' ? 'school' : 'university'
                ]
              }
              language={i18n.locale as any}
            ></LocalizedNameText>
          </span>
        </div>
      )
    } else if (columnKey == 'country') {
      return (
        <div>
          <LocalizedNameText
            localizedName={item[columnKey]}
            language={i18n.locale as any}
          ></LocalizedNameText>
        </div>
      )
    } else if (columnKey == 'school_university') {
      return (
        <div>
          <LocalizedNameText
            localizedName={item[columnKey]}
            language={i18n.locale as any}
          ></LocalizedNameText>
        </div>
      )
    } else return <span>{item[columnKey]}</span>
  }
  const reportsTableData = generateTableDataFromIthraaApplications(reports ?? ([] as Application[]))
  const handleChangePage = () => {
    setpage(page)
  }
  const filteredReportsData = searchText
    ? reportsTableData.filter((report) =>
        Object.values(report).some((value) =>
          value?.toString()?.toLowerCase().includes(searchText.toLowerCase())
        )
      )
    : reportsTableData
  return (
    <DataTable
      renderCell={renderCell}
      isLoading={isLoading}
      totalPages={pagination?.total_pages as number}
      page={page}
      onPageChange={handleChangePage}
      withColumnsFilter
      columns={columns}
      data={filteredReportsData as any}
    ></DataTable>
  )
}
