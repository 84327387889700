import { Application } from 'api/ithraaApisTypes'

interface Column {
  key: string
  label: string
}

const translations: Record<string, Record<string, string>> = {
  ar: {
    region_name: 'اسم المنطقة',
    educationAdministrationsCount: 'عدد الإدارات التعليمية بالمنطقة',

    // total_applications: 'عدد المشاركات بالمنطقة',
    total_general: 'المشاركات من التعليم العام',
    total_university: 'المشاركات من التعليم الجامعي',
    education_administration_name: 'الإدارة التعليمية',
    // male: 'عدد الذكور',
    // female: 'عدد الإناث',
    coordinator_name: 'اسم المنسق',
    percentage: 'مدى تحقيق المنسق للرقم المستهدف',
    points: 'عدد النقاط',
    universityName: 'الجامعة',
    region_name_associated: 'المنطقة التابعة',
    teacher_name: 'اسم المحاضر',
    country_name: 'اسم الدولة',
    draft_total: 'محفوظة كمسودة',
    pending_total: 'تم تسليم المشاركة',
    total_applications: 'مجموع المشاركات',
    male: 'مشاركات الذكور',
    female: 'مشاركات الإناث',
    student_personal_data: 'البيانات الشخصية للطالب',
    country: 'الدولة',
    region: 'المنطقة',
    educational_institution_info: 'معلومات الجهة التعلمية',
    school_university: 'المدرسة / الجامعة',
    submission_date: 'تاريخ التقديم',
    university_name: 'الجامعة'
  },
  en: {
    region_name: 'Region Name',
    educationAdministrationsCount: 'Number of Educational Administrations',
    total_applications: 'Number of Participants',
    total_general: 'Participations from General Education',
    total_university: 'Participations from University Education',
    education_administration_name: 'Educational Administration',
    maleCount: 'Number of Males',
    femaleCount: 'Number of Females',
    coordinator_name: 'Coordinator Name',
    percentage: 'Coordinator’s Achievement',
    points: 'Number of Points',
    universityName: 'University',
    region_name_associated: 'Associated Region',
    teacher_name: 'Teacher name',
    country_name: 'Country Name',
    draft_total: 'Saved as Draft',
    pending_total: 'Under Review',
    total_applications_count: 'Total Applications',
    male: 'Male Participants',
    female: 'Female Participants',
    student_personal_data: 'Student Personal Data',
    country: 'Country',
    region: 'Region',
    educational_institution_info: 'Educational Institution Information',
    school_university: 'School/University',
    submission_date: 'Submission Date',
    university_name: 'University'
  }
}

const headersMap: Record<string, string[]> = {
  regionalStagesApplications: [
    'region_name',
    'educationAdministrationsCount',
    'participantsCount',
    'generalEducationCount',
    'universityEducationCount'
  ],
  administrativesApplications: [
    'education_administration_name',
    'total_applications',
    'female',
    'male'
  ],
  administrativesApplicationsIthraa: [
    'education_administration_name',
    'total_applications',
    'female',
    'male'
  ],
  topCoordinators: [
    'region_name',
    'coordinator_name',
    'total_applications',
    'percentage',
    'points'
  ],
  countryTopCoordinators: ['region_name', 'coordinator_name', 'total_applications'],
  otherCountriesTopCoordinators: ['country_name', 'coordinator_name', 'total_applications'],
  countryTopTeachers: ['region_name', 'teacher_name', 'total_applications'],
  othersCountriesTopTeachers: ['country_name', 'teacher_name', 'total_applications'],
  topTeachers: ['region_name', 'teacher_name', 'total_applications', 'points'],
  topUniversities: ['universityName', 'region_name_associated', 'participantsCount'],
  reachPercentage: [
    'coordinator_name',
    'percentage',
    'total_applications',
    'region_name',
    'education_administration_name'
  ],
  universitiesApplications: ['university_name', 'region_name', 'total_applications'],
  countriesApplications: ['country_name', 'total_applications'],
  countriesApplicationsStatus: [
    'country_name',
    'draft_total',
    'pending_total',
    'total_applications'
  ],
  countryRegionalApplications: [
    'region_name',
    'draft_total',
    'pending_total',
    'total_applications'
  ],
  countryApplicationsCount: ['country_name', 'male', 'female', 'total_applications_count'],
  genderCountriesApplications: ['country_name', 'male', 'female', 'total_applications'],
  stagesApplications: ['region_name', 'universityEducationCount', 'generalEducationCount'],
  countryStagesApplications: ['region_name', 'total_university', 'total_general'],
  otherCountriesStagesApplications: ['country_name', 'total_university', 'total_general'],

  otherCountriesApplicationsPercentage: [
    'region_name',
    'educationAdministrationsCount',
    'participantsCount',
    'generalEducationCount',
    'universityEducationCount'
  ],
  dataTabelType: [
    'student_personal_data',
    'country',
    'region',
    'educational_institution_info',
    'submission_date'
  ]
}

export const renderTableHeaderTh = (key: string, language: 'ar' | 'en'): Column[] => {
  const headers = headersMap[key] || []
  return headers.map((headerKey) => ({
    key: headerKey,
    label: translations[language][headerKey]
  }))
}
type DataTableType =
  | 'student_personal_data'
  | 'country'
  | 'region'
  | 'educational_institution_info'
  | 'school_university'
  | 'submission_date'

export const ithraaTableDataGenerator = (application: Application): Record<DataTableType, any> => {
  return {
    student_personal_data: {
      first_name: application.personal_info.first_name,
      second_name: application.personal_info.second_name,
      last_name: application.personal_info.last_name,
      email: application.contact_info.email,
      phone: application.contact_info.phone
    },
    country: application.location.country?.name,
    region: application.location.region?.name,
    educational_institution_info: {
      school: application.education.school ? application.education.school.name : null,
      university: application.education.university ? application.education.university.name : null,
      education_stage: application.education.education_stage
    },
    school_university: application.education.school_university_name,
    submission_date: application.submission_at
  }
}
export const generateTableDataFromIthraaApplications = (
  applications: Application[]
): Record<DataTableType, any>[] => {
  return applications.map(ithraaTableDataGenerator)
}
