import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { ApplicationResponse, ApplicationByIdResponse, User } from 'api/types'
import { cleanObject } from 'utils/cleanObject'
import { yyyy_mm_dd } from 'utils/time'
import { userTypeEnum } from './authSlice'

export type ApplicationStatus = 'draft' | 'pending' | 'approved' | 'rejected' | 'pendingIdel'

export interface AppStatus {
  id: number
  name: {
    ar: string
    en: ApplicationStatus
  }
  default: boolean
}

export interface Student {
  id: number
  is_profile_completed: boolean | null
  name: string
  first_name: string
  middle_name: string | null
  second_name?: string | null
  last_name: string | null
  email: string
  phone: string
}

export interface PersonalInfo {
  gender?: string
  nationality_id?: string
  first_name: string
  second_name: string
  last_name: string
  birth_date: string
  age: number
}

export interface ContactInfo {
  email: string
  phone: string
}

export interface Location {
  region_id: number
  city_id: number | null
  country_id: number
  country_code: string
}

export interface Education {
  education_stage: string
  school_id: number | null
  university_id: number | null
  school_university_name: string
  education_administration_id: number | null
  education_level_id: number
}

export interface ApplicationBody {
  teacher_code?: string
  terms_agreed?: boolean
  book_title: string
  book_author: string
  book_reason: string
  book_review: string
  story: string
}

export interface Application {
  id: number
  number: string
  status: AppStatus
  student_id: number
  student: Student
  personal_info: PersonalInfo
  contact_info: ContactInfo
  location: Location
  education: Education
  application_body: ApplicationBody
  submission_at: string | null
  last_response_at: string | null
  status_id: number
  teacher_id: number | null
  teacher: Teacher | null
  competition_year_id: number
  total_points: number
  created_at: string
  updated_at: string
  workflow_assign_type: 'admin' | 'system'
  workflow_assigned_at: string
  workflow_assigned_by: User
}

interface Teacher {
  name: string
}

interface ApplicationState {
  apps: Application[] | []
  isLoading: boolean
  error: string | null
  competition_year_id: string
  search?: string
  status?: string
  submission_at_from?: string
  submission_at_to?: string
  page: number
  per_page: number
  total_pages: number
}

const initialState: ApplicationState = {
  apps: [],
  isLoading: false,
  error: null,
  competition_year_id: '1',
  search: '',
  status: undefined,
  submission_at_from: undefined,
  submission_at_to: undefined,
  page: 1,
  per_page: 6,
  total_pages: 0
}

export interface ISearchApp {
  user: userTypeEnum
  competition_year_id?: string
  search?: string
  status?: number
  submission_at_from?: string
  submission_at_to?: string
  page?: number
  per_page?: number
  idle?: boolean
  'statuses[]'?: number
}

const collKeys = {
  student: 'students',
  teacher: 'teachers',
  coordinator: 'coordinators',
  ithraa: 'management'
}

export const fetchApps = createAsyncThunk('applications/fetch', async (params: ISearchApp) => {
  const { submission_at_from, submission_at_to, idle } = params

  const collection = collKeys[params.user as keyof typeof collKeys]
  if (!collection) return null
  delete (params as Partial<typeof params>).user
  delete (params as Partial<typeof params>).idle

  const cleanedParams = cleanObject({
    ...params,
    // statuses: params.statuses ? params.statuses.join(',') : undefined,
    submission_at_from: submission_at_from ? yyyy_mm_dd(submission_at_from) : '',
    submission_at_to: submission_at_to ? yyyy_mm_dd(submission_at_to) : ''
  })

  const response = await axiosInstance.get<ApplicationResponse>(
    `/${collection}/applications${idle ? '/idle' : ''}`,
    {
      params: { ...cleanedParams }
    }
  )

  return response.data
})

export const fetchApplicationById = createAsyncThunk(
  'applications/fetchById',
  async ({ id, userType, idle }: { id: number; userType: userTypeEnum; idle?: boolean }) => {
    const collection = collKeys[userType as keyof typeof collKeys]
    const response = await axiosInstance.get<ApplicationByIdResponse>(
      `/${collection}/applications/${idle ? 'idle/' : ''}${id}`
    )
    return response.data
  }
)

const appsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    setApps: (state, action: PayloadAction<Application[]>) => {
      state.apps = action.payload
    },
    setQ: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setSubmitFrom: (state, action: PayloadAction<string | undefined>) => {
      state.submission_at_from = action.payload
    },
    setSubmitTo: (state, action: PayloadAction<string | undefined>) => {
      state.submission_at_to = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.per_page = action.payload
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApps.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchApps.fulfilled, (state, action) => {
        if (!action.payload) return
        state.isLoading = false
        state.apps = action.payload.data
        state.total_pages = action.payload.meta?.pagination?.total_pages
        state.page = action.payload.meta?.pagination?.current_page
        state.per_page = action.payload.meta?.pagination?.per_page
      })
      .addCase(fetchApps.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
      .addCase(fetchApplicationById.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchApplicationById.fulfilled, (state, action) => {
        state.isLoading = false
        const appIndex = state.apps.findIndex((app) => app.id === action.payload.data.id)
        if (appIndex !== -1) {
          state.apps[appIndex] = action.payload.data
        } else {
          // @ts-ignore
          state.apps.push(action.payload.data)
        }
      })
      .addCase(fetchApplicationById.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export const { setApps, setQ, setSubmitFrom, setSubmitTo, setPage, setPerPage, setStatus } =
  appsSlice.actions

export default appsSlice
