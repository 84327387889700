import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import ButtonVariant from 'components/button-variant/button'
import ModalLayout from 'components/modal/modal-layout'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function ResetProcess() {
  const { _ } = useLingui()
  const navigate = useNavigate()
  const onBack = () => {
    navigate('/login')
  }

  return (
    <ModalLayout
      showBackButton={false}
      body={
        <div className="flex flex-col gap-4 items-center w-full">
          <h4 className="font-heading-h4">{_(msg`إعادة تعيين كلمة المرور`)}</h4>
          <span className="text-center">
            {_(msg`
يرجى مراجعة بريدك الإلكتروني لإكمال إجراءات تسجيل دخولك وإعادة تعيين كلمة المرور.`)}
          </span>
          <ButtonVariant variant="secondary" onClick={onBack}>
            {_(msg`الرجوع`)}
          </ButtonVariant>
        </div>
      }
    />
  )
}
