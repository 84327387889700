import { AppDispatch, RootState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { fetchMe } from 'store/slices/meSlice'
import useLanguage from 'hooks/useLanguage'
import { z } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import TextInput from 'components/hook-form/text-input'
// import DateInput from 'components/hook-form/date-input'
import DateInput from 'components/hook-form/date-picker-input'
import { useNationalityOptions } from './hook/nationalties-option'
import { useRegionOptions } from './hook/regions-options'
import { useEducationAdministrationOptions } from './hook/eduction-adminstrations-options'
import { useEducationLevelsOptions } from './hook/education-levels-options'
import { useSchoolOptions } from './hook/schools-options'
import { useUniversityOptions } from './hook/university-options'
import ButtonVariant from 'components/button-variant/button'
import {
  getAccountType,
  getSchool,
  getUniversity,
  isLocatedInSA,
  isUserOfType,
  mapBackendDataToState,
  useEducationStageOptions,
  useGenderOptions
} from './utils'
import { usePersonalInfoSchema } from './schema'
import { updateProfile } from 'store/slices/updateProfileSlice'
import Alert from 'components/alert/alert'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'
import { useCountryOptions } from './hook/countries-option'
import { getRegionLabelFromCountry } from 'pages/coordinator-active-account/utils'
import SelectInput from 'components/hook-form/select-field'

function PersonalInfoExtra() {
  const { _, i18n } = useLingui()
  const dispatch = useDispatch<AppDispatch>()
  const { me } = useSelector((state: RootState) => state.me)
  const language = useLanguage() as 'ar' | 'en' // Type assertion
  const [countryId, setCountryId] = useState<string | null>(null)
  const [countryCode, setCountryCode] = useState<string | null>(null)
  const [accountType, setAccountType] = useState<
    'student' | 'teacher' | 'coordinator' | 'ithraa' | 'admin' | 'judge' | null
  >(null)
  const [school, setSchool] = useState<string | null>(null)
  const [university, setUniversity] = useState<string | null>(null)
  const [educationStageState, setEducationStage] = useState<string | null>(null)
  const [regionId, setRegionId] = useState<string | null>(null)
  const [showAlert, setShowAlert] = useState<{ type: 'success' | 'error'; message: string } | null>(
    null
  )
  const [regionLabelCountry, setRegionLabelCountry] = useState<string | null>(null)

  const { options: countryOptions, isLoading: CountryOptionsLoading } = useCountryOptions()
  const { options: nationalityOptions, isLoading: NationalityOptionsLoading } =
    useNationalityOptions()
  const { options: regionOptions, isLoading: RegionOptionsLoading } = useRegionOptions(
    countryId!,
    countryCode!
  )
  const {
    options: educationAdministrationOptions,
    isLoading: EducationAdministrationOptionLoading
  } = useEducationAdministrationOptions({
    countryId: countryId!,
    countryCode: countryCode!,
    regionId: regionId!
  })

  const genderOptions = useGenderOptions()
  const educationStageOptions = useEducationStageOptions()
  const {
    options,
    selectedEducationStage,
    handleEducationLevelChange,
    isLoading: EducationLevelOptionsLoading
  } = useEducationLevelsOptions({
    countryId: countryId!,
    countryCode: countryCode!
  })
  const { options: schoolOptions, isLoading: SchoolOptionLoading } = useSchoolOptions({
    countryId: countryId!,
    countryCode: countryCode!,
    regionId: regionId!
  })
  const { options: universitiesOptions, isLoading: UniversityOptionsLoading } =
    useUniversityOptions({
      countryId: countryId!,
      countryCode: countryCode!,
      regionId: regionId!
    })

  const personalInfoSchema = usePersonalInfoSchema(
    accountType,
    educationStageState,
    countryId === '425' || countryCode === 'SA',
    school,
    university
  )

  // Infer the type from the schema
  type PersonalInfoSchemaType = z.infer<typeof personalInfoSchema>

  // Initialize the form
  const methods = useForm<PersonalInfoSchemaType>({
    resolver: zodResolver(personalInfoSchema),
    defaultValues: {
      first_name: '',
      middle_name: '',
      last_name: '',
      gender: '',
      phone_number: '',
      date_of_birth: undefined,
      country: undefined,
      nationality: undefined,
      region_id: null,
      education_stage: undefined,
      school_university_name: '',
      education_administration_id: undefined,
      education_level_id: undefined,
      school_id: undefined,
      university_id: undefined
    }
  })

  // Extract methods for form handling
  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isSubmitting }
  } = methods

  const educationStage = watch('education_stage')
  const region = watch('region_id')
  const country = watch('country')
  const educationLevel = watch('education_level_id')
  const schoolId = watch('school_id')
  const universityId = watch('university_id')
  const onSubmit = async (data: PersonalInfoSchemaType) => {
    try {
      if (data.education_stage === 'university') {
        data.education_administration_id = null
        data.school_id = null
      } else if (data.education_stage === 'general') {
        data.university_id = null
      }
      if (data.school_id === 'other' || data.university_id === 'other') {
        data.school_id = null
        data.university_id = null
      } else {
        data.school_university_name = ''
      }
      const result = await dispatch(updateProfile(data)).unwrap()

      if (result.status === 'success') {
        await dispatch(fetchMe()).unwrap()
        setShowAlert({ type: 'success', message: _(msg`تم حفظ التحديثات بنجاح`) })
      }
    } catch (error: any) {
      const errorMessage = i18n._(error.message) || i18n._(msg`هنالك خطأ`)
      setShowAlert({ type: 'error', message: errorMessage })
    }
  }
  const onError = (errors: any) => {
    console.log('Validation Errors:', errors)
  }

  useEffect(() => {
    if (me?.data) {
      const mappedData = mapBackendDataToState(me.data, language)
      setAccountType(getAccountType(me?.data))
      setCountryId(String(mappedData?.country?.id))
      setCountryCode(String(mappedData?.country?.code))
      setRegionId(String(mappedData?.region?.id))
      if (me.data.country.region_label)
        setRegionLabelCountry(getRegionLabelFromCountry(me.data.country.region_label, language))

      // Conditionally set school and university based on the account type
      if (isUserOfType(accountType, ['student', 'teacher', 'coordinator'])) {
        setSchool(getSchool(me?.data) || '')
        setUniversity(getUniversity(me?.data) || '')
      } else {
        setSchool(null)
        setUniversity(null)
      }

      let schoolValue = undefined
      let universityValue = undefined
      if (mappedData.school_university_name) {
        if (mappedData.education_stage === 'general') {
          schoolValue = 'other'
          setSchool('other')
        } else if (mappedData.education_stage === 'university') {
          universityValue = 'other'
          setUniversity('other')
        }
      }

      reset({
        first_name: mappedData.firstName || '',
        middle_name: mappedData.middleName || '',
        last_name: mappedData.lastName || '',
        gender: mappedData.gender || '',
        phone_number: mappedData.phoneNumber || '',
        date_of_birth: mappedData.dateOfBirth || undefined,
        country: mappedData.country?.id?.toString() || undefined,
        nationality: mappedData.nationality?.id?.toString() || undefined,
        region_id: mappedData.region?.id?.toString() || undefined,
        education_stage: mappedData.education_stage || undefined,
        school_university_name: mappedData.school?.name || mappedData.school_university_name || '',
        education_administration_id:
          mappedData.educationAdministration?.id?.toString() || undefined,
        education_level_id: mappedData.educationLevel?.id?.toString() || undefined,
        school_id: schoolValue ?? mappedData.school?.id?.toString(),
        university_id: universityValue ?? mappedData.university?.id?.toString()
      })
    }
  }, [accountType, language, me, reset])

  useEffect(() => {
    dispatch(fetchMe())
  }, [dispatch])

  useEffect(() => {
    if (educationStage) setEducationStage(educationStage)
    if (country) {
      setCountryId(country)
      // setCountryCode(null)
    }
    if (region) setRegionId(String(region))
  }, [country, educationStage, region])

  useEffect(() => {
    if (schoolId === 'other' || universityId === 'other') {
      setValue('school_university_name', '')
      setSchool(schoolId || null)
      setUniversity(universityId || null)
    }
  }, [schoolId, universityId, setValue])

  useEffect(() => {
    if (selectedEducationStage && isUserOfType(accountType, ['student'])) {
      setValue('education_stage', selectedEducationStage)
    }
  }, [accountType, selectedEducationStage, setValue])

  useEffect(() => {
    if (educationLevel) {
      handleEducationLevelChange(educationLevel)
    }
  }, [educationLevel, handleEducationLevelChange])
  return (
    <FormProvider {...methods}>
      {showAlert && (
        <Alert
          closable
          mainText={showAlert.message}
          sizeMainText="md"
          weightMainText="normal"
          onClose={() => setShowAlert(null)}
          type={showAlert.type}
        />
      )}
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full"
      >
        <TextInput
          name="first_name"
          label={_(msg`الاسم الأول`)}
          width="100%"
          placeholder={_(msg`الاسم الأول`)}
        />
        <TextInput
          name="middle_name"
          label={_(msg`الاسم الأوسط`)}
          width="100%"
          placeholder={_(msg`الاسم الأوسط`)}
        />
        <TextInput
          name="last_name"
          label={_(msg`الاسم الأخير`)}
          width="100%"
          placeholder={_(msg`الاسم الأخير`)}
        />
        {isUserOfType(accountType, ['student', 'teacher', 'coordinator']) && (
          <>
            <TextInput
              name="phone_number"
              width="100%"
              label={_(msg`رقم الجوال`)}
              type="phone-number"
            />
            <SelectInput
              name="gender"
              label={_(msg`الجنس`)}
              options={genderOptions}
              selectionMode="single"
              placeholder={_(msg`اختر الجنس`)}
            />
            {/* <DateInput name="date_of_birth" label={_(msg`تاريخ الميلاد`)} /> */}
            <DateInput
              name="date_of_birth"
              label={_(msg`تاريخ الميلاد`)}
              placeholder={_(msg`أدخل تاريخ ميلادك`)}
            />
          </>
        )}
        {isUserOfType(accountType, ['student', 'teacher', 'coordinator']) && (
          <>
            <SelectInput
              name="nationality"
              label={_(msg`الجنسية`)}
              isLoading={NationalityOptionsLoading}
              options={nationalityOptions}
              selectionMode="single"
              mode="search"
              placeholder={_(msg`اختر الجنسية`)}
            />

            <SelectInput
              name="country"
              isLoading={CountryOptionsLoading}
              label={_(msg`الدولة`)}
              options={countryOptions}
              selectionMode="single"
              mode="search"
              placeholder={_(msg`اختر الدولة`)}
            />
          </>
        )}
        {isUserOfType(accountType, ['student', 'teacher', 'coordinator']) && (
          <SelectInput
            name="region_id"
            label={regionLabelCountry ? regionLabelCountry : _(msg`المنطقة`)}
            options={regionOptions}
            isLoading={RegionOptionsLoading}
            isDisabled={!watch('country') || isUserOfType(accountType, ['coordinator'])}
            placeholder={_(msg`اختر المنطقة`)}
            mode="search"
          />
        )}
        {isUserOfType(accountType, ['student', 'teacher', 'coordinator']) && (
          <>
            <SelectInput
              name="education_stage"
              label={_(msg`مرحلة التعليم`)}
              options={educationStageOptions} // Options from the hook
              selectionMode="single"
              isDisabled={
                isUserOfType(accountType, ['student']) || isUserOfType(accountType, ['coordinator'])
              }
              placeholder={_(msg`اختر مرحلة التعليم`)}
            />
            {educationStage === 'general' && isLocatedInSA(countryId as string, countryCode as string) && (
              <SelectInput
                name="education_administration_id"
                label={_(msg`الإدارة التعليمية`)}
                selectionMode="single"
                isDisabled={isUserOfType(accountType, ['coordinator'])}
                isLoading={EducationAdministrationOptionLoading}
                options={educationAdministrationOptions}
                placeholder={_(msg`اختر إدارة تعليمية`)}
                mode="search"
              />
            )}
          </>
        )}
        {isUserOfType(accountType, ['student']) && (
          <SelectInput
            name="education_level_id"
            label={_(msg`المستوى التعليمي`)}
            selectionMode="single"
            isLoading={EducationLevelOptionsLoading}
            options={options}
            placeholder={_(msg`اختر مستوى تعليمي`)}
          />
        )}
        {educationStage === 'general' &&
          isUserOfType(accountType, ['student', 'teacher', 'coordinator']) && (
            <SelectInput
              name="school_id"
              label={_(msg`المدرسة`)}
              isLoading={SchoolOptionLoading}
              selectionMode="single"
              isDisabled={isUserOfType(accountType, ['coordinator'])}
              options={schoolOptions}
              showDefaultOptionWhenNoResults={true}
              mode="search"
              placeholder={_(msg`اختر مدرسة`)}
              defaultOption={{ label: i18n._(msg`المدرسة غير مدرجة في القائمة`), value: 'other' }}
            />
          )}
        {educationStage === 'university' &&
          isUserOfType(accountType, ['student', 'teacher', 'coordinator']) && (
            <SelectInput
              name="university_id"
              label={_(msg`الجامعة`)}
              isLoading={UniversityOptionsLoading}
              isDisabled={isUserOfType(accountType, ['coordinator'])}
              selectionMode="single"
              options={universitiesOptions}
              showDefaultOptionWhenNoResults={true}
              mode="search"
              placeholder={_(msg`اختر جامعة`)}
            />
          )}
        {((educationStage === 'general' && (schoolId === 'other' || school === 'other')) ||
          (educationStage === 'university' &&
            (universityId === 'other' || university === 'other'))) && (
          <TextInput
            name="school_university_name"
            label={_(msg`اسم المدرسة / الجامعة`)}
            placeholder={_(msg`أدخل مدرستك أو جامعنك`)}
          />
        )}

        <div className="col-span-full flex justify-end">
          <ButtonVariant
            type="submit"
            variant="secondary"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            {_(msg`تحديث`)}
          </ButtonVariant>
        </div>
      </form>
    </FormProvider>
  )
}

export default PersonalInfoExtra
