import { z } from 'zod'
import { msg } from '@lingui/macro'

const minAge = 8
const maxAge = 25
const today = new Date()
const maxDate = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate())
const minDate = new Date(today.getFullYear() - maxAge, today.getMonth(), today.getDate())

export const personalInfoSchema = z.object({
  first_name: z.string({ message: msg`هذا الحقل مطلوب`.id }).min(1, msg`هذا الحقل مطلوب`.id),

  second_name: z.string({ message: msg`هذا الحقل مطلوب`.id }).min(1, msg`هذا الحقل مطلوب`.id),

  last_name: z.string({ message: msg`هذا الحقل مطلوب`.id }).min(1, msg`هذا الحقل مطلوب`.id),

  date_of_birth: z.string({ message: msg`هذا الحقل مطلوب`.id }).refine(
    (dateStr) => {
      const date = new Date(dateStr)
      return !isNaN(date.getTime()) && date >= minDate && date <= maxDate
    },
    {
      message: msg`تاريخ الميلاد غير صالح. العمر المطلوب لمشاركة الطالب من ٨ - ٢٥ سنة`.id
    }
  ),

  age: z.union([z.string({ message: msg`هذا الحقل مطلوب`.id }), z.number()]).refine(
    (value) => {
      const age = typeof value === 'string' ? parseInt(value, 10) : value
      return !isNaN(age) && age >= minAge && age <= maxAge
    },
    {
      message: msg`العمر المطلوب لمشاركة الطالب من ٨ - ٢٥ سنة`.id
    }
  ),

  gender: z
    .string({ message: msg`هذا الحقل مطلوب`.id })
    .refine((value) => !!value, { message: msg`هذا الحقل مطلوب`.id }),

  nationality: z
    .union([z.string({ message: msg`هذا الحقل مطلوب`.id }), z.number()])
    .refine((value) => !!value, { message: msg`هذا الحقل مطلوب`.id })
})

export const contactInfoSchema = z.object({
  email: z.string({ message: msg`هذا الحقل مطلوب`.id }),
  phone: z
    .string()
    .trim()
    .min(1, { message: msg`رقم الجوال مطلوب`.id })
    .refine(
      (value) => {
        const phoneWithoutCountryCode = value.replace(/^\+\d{1,4}\s*/, '')
        return /^\d{7,}$/.test(phoneWithoutCountryCode)
      },
      { message: msg`الرجاء إدخال رقم جوال صحيح`.id }
    )
})

export const locationInfoSchema = z.object({
  country: z
    .union([z.string({ message: msg`هذا الحقل مطلوب`.id }), z.number()])
    .refine((value) => !!value, { message: msg`هذا الحقل مطلوب`.id }),

  region: z
    .union([z.string({ message: msg`هذا الحقل مطلوب`.id }), z.number()])
    .refine((value) => !!value, { message: msg`هذا الحقل مطلوب`.id })
})

export const educationInfoSchema = z
  .object({
    education_stage: z
      .string({ message: msg`هذا الحقل مطلوب`.id })
      .min(1, { message: msg`هذا الحقل مطلوب`.id }),

    education_level: z
      .union([z.string({ message: msg`هذا الحقل مطلوب`.id }), z.number()])
      .nullable(),

    education_administration: z
      .union([z.string({ message: msg`هذا الحقل مطلوب`.id }), z.number()])
      .nullable(),

    school: z.union([z.string({ message: msg`هذا الحقل مطلوب`.id }), z.number()]).nullable(),

    university: z.union([z.string({ message: msg`هذا الحقل مطلوب`.id }), z.number()]).nullable(),

    school_university_name: z
      .union([z.string({ message: msg`هذا الحقل مطلوب`.id }), z.number()])
      .nullable(),

    country: z.string({ message: '' }).nullable(),
    countryCode: z.string({ message: '' }).nullable()
  })
  .superRefine(({ education_level }, ctx) => {
    if (education_level === null) {
      ctx.addIssue({
        code: 'custom',
        message: msg`هذا الحقل مطلوب`.id,
        path: ['education_level']
      })
    }
  })
  .superRefine(({ country, countryCode, education_stage, education_administration }, ctx) => {
    if (
      education_stage === 'general' &&
      (country === '425' || countryCode === 'SA') &&
      education_administration === null
    ) {
      ctx.addIssue({
        code: 'custom',
        message: msg`هذا الحقل مطلوب`.id,
        path: ['education_administration']
      })
    }
  })
  .superRefine(({ school, university }, ctx) => {
    if (school === null && university === null) {
      ctx.addIssue({
        code: 'custom',
        message: msg`هذا الحقل مطلوب`.id,
        path: ['school']
      })
      ctx.addIssue({
        code: 'custom',
        message: msg`هذا الحقل مطلوب`.id,
        path: ['university']
      })
    }
  })
  .superRefine(({ school, university, school_university_name }, ctx) => {
    if (
      (school === 'other' || university === 'other') &&
      (school_university_name === null ||
        school_university_name === '' ||
        school_university_name === undefined)
    ) {
      ctx.addIssue({
        code: 'custom',
        message: msg`هذا الحقل مطلوب`.id,
        path: ['school_university_name']
      })
    }
  })

export const applicationBodySchema = z.object({
  reading_story: z
    .string({ message: msg`هذا الحقل مطلوب`.id })
    .refine(
      (val) => val.trim().split(/\s+/).length >= 50 && val.trim().split(/\s+/).length <= 500,
      { message: msg`يجب ألا يقل طول الحقل عن 50 كلمة ولا يزيد عن 500 كلمة.`.id }
    ),

  book_title: z
    .string({ message: msg`هذا الحقل مطلوب`.id })
    .min(3, { message: msg`يجب ألا يقل طول الحقل عن 3 حروف.`.id }),

  author_name: z
    .string({ message: msg`هذا الحقل مطلوب`.id })
    .min(3, { message: msg`يجب ألا يقل طول الحقل عن 3 حروف.`.id }),

  reason_for_choice: z
    .string({ message: msg`هذا الحقل مطلوب`.id })
    .refine(
      (val) => val.trim().split(/\s+/).length >= 50 && val.trim().split(/\s+/).length <= 500,
      { message: msg`يجب ألا يقل طول الحقل عن 50 كلمة ولا يزيد عن 500 كلمة.`.id }
    ),

  book_review: z
    .string({ message: msg`هذا الحقل مطلوب`.id })
    .refine(
      (val) => val.trim().split(/\s+/).length >= 250 && val.trim().split(/\s+/).length <= 1000,
      { message: msg`يجب ألا يقل طول الحقل عن 250 كلمة ولا يزيد عن 1000 كلمة.`.id }
    ),

  teacher_code: z.string({ message: '' }).optional(),

  terms_agreed: z
    .boolean({ message: msg`يجب قبول شروط وأحكام المسابقة`.id })
    .refine((value) => value === true, { message: msg`يجب قبول شروط وأحكام المسابقة`.id })
})

export const formSchema = z.object({
  personal_info: personalInfoSchema,
  contact_info: contactInfoSchema,
  location_info: locationInfoSchema,
  education_info: educationInfoSchema,
  application_body: applicationBodySchema
})
