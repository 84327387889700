import React from 'react'

interface Props {
  fill?: string
}

const EmptyIcon: React.FC<Props> = ({ fill = 'white' }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">

    </svg>
  )
}

export default EmptyIcon
