import { LocalizedName, PersonalInfoState } from '../type'
import { User } from 'api/types'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'

export const useGenderOptions = () => {
  const { _ } = useLingui()
  return [
    { key: 'male', label: _(msg`ذكر`) },
    { key: 'female', label: _(msg`انثى`) }
  ]
}

export const useEducationStageOptions = () => {
  const { _ } = useLingui()
  return [
    { key: 'general', label: _(msg`عام`) },
    { key: 'university', label: _(msg`جامعي`) }
  ]
}

export const useGenderOfEducationOptions = () => {
  const { _ } = useLingui()
  return [
    { key: 'both', label: _(msg`كلاهما`) },
    { key: 'male', label: _(msg`ذكر`) },
    { key: 'female', label: _(msg`انثى`) }
  ]
}

export const getLocalizedName = (localizedName: LocalizedName, language: 'ar' | 'en') => {
  return localizedName[language] || localizedName.en // Fallback to English if needed
}

export function mapBackendDataToState(data: any, language: 'ar' | 'en'): PersonalInfoState {
  return {
    firstName: data.name,
    emailAddress: data.email,
    gender: data.gender,
    phoneNumber: data.phone,
    country: data.country
      ? {
          id: data.country.id,
          name: getLocalizedName(data.country.name, language),
          code: data.country_code
        }
      : null,
    middleName: data.middle_name,
    lastName: data.last_name,
    nationality: data.nationality
      ? {
          id: data.nationality.id,
          name: getLocalizedName(data.country.name, language)
        }
      : null,
    region: data.region
      ? {
          id: data.region.id,
          name: getLocalizedName(data.country.name, language)
        }
      : null,
    dateOfBirth: data.date_of_birth,
    educationLevel: data.education_level
      ? {
          id: data.education_level.id,
          name: getLocalizedName(data.country.name, language)
        }
      : null,

    education_stage: data.education_stage,

    educationAdministration: data.education_administration
      ? {
          id: data.education_administration.id,
          name: getLocalizedName(data.country.name, language)
        }
      : null,
    school: data.school
      ? {
          id: data.school.id,
          name: getLocalizedName(data.country.name, language)
        }
      : null,
    university: data.university
      ? {
          id: data.university.id,
          name: getLocalizedName(data.country.name, language)
        }
      : null,
    school_university_name: data.school_university_name
  }
}

export function getCountryCode(data: User) {
  return data.country.code
}

export function getCountryId(data: User) {
  return data.country.id
}

export function getAccountType(data: User) {
  return data.account_type
}

export function getSchool(data: User) {
  return data.school?.name?.en || '' // Check if school and name exist, return empty string if not
}

export function getUniversity(data: User) {
  return data.university?.name?.en || '' // Check if university and name exist, return empty string if not
}

// Helper functions to check user type and location
export const isUserOfType = (
  accountType: 'student' | 'teacher' | 'coordinator' | 'ithraa' | 'admin' | 'judge' | null,
  types: string[]
) => types.includes(accountType || '')
export const isLocatedInSA = (countryId: string | null, countryCode: string) => countryId === '425' || countryCode === 'SA'
