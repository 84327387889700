import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Country, User } from 'pages/AccountPages/types'
import ModalLayout from 'components/modal/modal-layout'
import ModalHeader from 'components/modal/modal-header'
import UserRegistration from '../forms/user-registration'
import { Trans } from '@lingui/macro' // Use Trans from @lingui/macro only
import { msg } from '@lingui/macro'
import Link from 'components/base/link'
import { useLingui } from '@lingui/react'

interface RegistrationModalProps {
  countries?: Country[]
  selectedUser?: User | null
  setSelectedUser?: React.Dispatch<User | null>
  isHidden: boolean
  setShowTermsModal: (show: boolean) => void
  setShowOTPModal: (show: boolean) => void
}

const RegistrationModal: React.FC<RegistrationModalProps> = ({
  countries,
  selectedUser,
  setSelectedUser,
  isHidden,
  setShowTermsModal,
  setShowOTPModal
}) => {
  const navigate = useNavigate()
  const modalContentRef = useRef<HTMLDivElement>(null)
  const { i18n } = useLingui()

  const handleBack = () => {
    if (setSelectedUser) {
      setSelectedUser(null)
    }
  }

  const handleClick = () => {
    navigate('/login')
  }

  return (
    <>
      {selectedUser && (
        <ModalLayout
          className={`${isHidden ? 'opacity-0' : ''} transition-opacity ease-in-out duration-500`}
          onBack={handleBack}
          accountPageStyle
          placement="top"
          scrollBehavior="outside"
          header={
            <ModalHeader
              headerText={selectedUser.modalTitle}
              subheaderText={i18n._(selectedUser.description.id)}
            />
          }
          body={
            <div ref={modalContentRef} style={{ maxHeight: '500px', overflowY: 'auto' }}>
              {countries && (
                <UserRegistration
                  countries={countries}
                  account_type={selectedUser.type}
                  setShowOTPModal={setShowOTPModal}
                  setShowTermsModal={setShowTermsModal}
                  scrollToTop={() => {
                    if (modalContentRef.current) {
                      modalContentRef.current.scrollTo({ top: 0, behavior: 'smooth' })
                      window.scrollTo({ top: 0, behavior: 'smooth' })
                      document.documentElement.scrollTop = 0
                      document.body.scrollTop = 0
                    }
                  }}
                />
              )}
            </div>
          }
          footer={
            <div className="mx-auto flex items-center justify-center">
              <p className="mr-1 ml-1 mb-3">
                <Trans>لديك حساب سابق؟</Trans>
              </p>
              <Link
                placeholder={msg`سجل دخولك`}
                className={'font-label-h5-light-ar'}
                onClick={handleClick}
              />
            </div>
          }
        />
      )}
    </>
  )
}

export default RegistrationModal
