import React, { useState, forwardRef } from 'react'
import { Input, InputProps } from '@nextui-org/react'
import { useController, useFormContext } from 'react-hook-form'
import {
  ParsedCountry,
  PhoneInput,
  defaultCountries,
  parseCountry
} from 'react-international-phone'
import 'react-international-phone/style.css'
import './phone-input.css'
import EyeOffIcon from 'assets/icons/EyeOffIcon'
import EyeIcon from 'assets/icons/EyeIcon'
import { Trans, useLingui } from '@lingui/react'
import classNames from 'classnames'
import Label from 'components/label/Label'
import { CountryIsoCodes, countryTranslations, SupportedLocales } from './countryTranslations'
import { allCountries } from './countryList'

type InputFieldProps = InputProps & {
  label?: string
  helperText?: React.ReactNode
  error?: string | null
  type?: string
  invisible?: boolean
  placeholder?: string
  isDisabled?: boolean
  isReadOnly?: boolean
  className?: string
  phone?: string
  tooltipInfoText?: string
  labelClassName?: string
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      label,
      helperText,
      error,
      className,
      type = 'text',
      invisible = false,
      placeholder,
      isDisabled,
      tooltipInfoText,
      labelClassName,
      phone,
      ...other
    },
    ref
  ) => {
    const { control } = useFormContext()
    const [isVisible, setIsVisible] = useState(false)
    const { i18n } = useLingui()
    const [dynamicPlaceholder, setDynamicPlaceholder] = useState<string>('500000000')

    const [errorText, setErrorText] = useState<string | null | undefined>(error)

    const toggleVisibility = () => setIsVisible(!isVisible)

    const isPasswordType = type === 'password'
    const isPhoneNumberType = type === 'phone-number'

    const { field } = useController({
      name: other.name!,
      control,
      rules: { required: i18n._(`هذا الحقل مطلوب`) }
    })

    const handlePhoneChange = (
      value: string,
      meta: { country: ParsedCountry; inputValue: string }
    ) => {
      if (errorText) setErrorText(null)
      // Update the form field value
      field.onChange(value)

      // If format is a string, update the placeholder, otherwise fallback to dialCode and country name
      const format = meta.country.format
      if (typeof format === 'string') {
        setDynamicPlaceholder(format) // Set placeholder using the format string
      } else {
        const countryIso2 = meta.country.iso2 as CountryIsoCodes
        setDynamicPlaceholder(
          i18n._(`أدخل رمز الدولة : `) +
            ` ${translateCountryNames(countryIso2)} (+${meta.country.dialCode})`
        )
      }
    }

    const translateCountryNames = (iso2: string) => {
      const locale = i18n.locale === 'ar' ? 'ar' : 'en'
      const country = allCountries.find((country) => country.iso2 === iso2)
      return country ? country[`name_${locale}`] : iso2
    }

    const countries = defaultCountries
      .filter((country) => {
        const { iso2 } = parseCountry(country)
        return !['il'].includes(iso2)
      })
      .map((country) => {
        const parsedCountry = parseCountry(country)
        country[0] = translateCountryNames(parsedCountry.iso2)
        //country[2] = ` ${parsedCountry.dialCode} `
        return country
      })

    if (invisible) {
      return null
    }
    return (
      <div className="flex flex-col justify-start gap-1">
        {label && (
          <Label labelClassName={labelClassName} label={label} tooltipInfoText={tooltipInfoText} />
        )}
        {isPhoneNumberType ? (
          <>
            <PhoneInput
              disabled={isDisabled}
              ref={ref as any} // Ref forwarding
              placeholder={dynamicPlaceholder}
              onChange={handlePhoneChange}
              value={(field.value ?? phone) || ''}
              onBlur={field.onBlur}
              className={classNames({
                'w-full font-sub-heading-h6-ar text-natural_icon_normal placeholder:text-primary_lv2_text_normal':
                  true,
                'border-danger_default_stroke_normal': Boolean(error),
                [`${className}`]: Boolean(className)
              })}
              style={{
                direction: 'ltr',
                width: '100%'
              }}
              defaultCountry="sa"
              countries={countries}
            />
            {error && (
              <p className="mx-2 text-danger_default_lv1_text_normal_active_hover_pressed font-label-sm-ar">
                <Trans id={error} />
              </p>
            )}
          </>
        ) : (
          <>
            <Input
              isDisabled={isDisabled}
              ref={ref} // Ref forwarding
              aria-label={label || 'input component'}
              placeholder={i18n._(placeholder || '')}
              color="primary"
              variant="bordered"
              type={isPasswordType && isVisible ? 'text' : type}
              radius="sm"
              fullWidth
              className={classNames({
                'w-full rounded-lg font-sub-heading-h6-ar text-natural_icon_normal placeholder:text-primary_lv2_text_normal':
                  true,
                'border-danger_default_stroke_normal': Boolean(error),
                'rounded-xl bg-input_disabled text-black-300': Boolean(isDisabled),
                [`${className}`]: Boolean(className)
              })}
              helperText={error || helperText}
              endContent={
                isPasswordType && (
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={toggleVisibility}
                    aria-label="toggle password visibility"
                  >
                    {isVisible ? <EyeOffIcon /> : <EyeIcon />}
                  </button>
                )
              }
              {...other}
            />
            {error && (
              <p className="mx-2 text-danger_default_lv1_text_normal_active_hover_pressed font-label-sm-ar">
                <Trans id={error} />
              </p>
            )}
          </>
        )}
        {/* {errorText && (
          <p className="mx-2 text-danger_default_lv1_text_normal_active_hover_pressed font-label-sm-ar">
            <Trans id={errorText} />
          </p>
        )} */}
      </div>
    )
  }
)

export default InputField
