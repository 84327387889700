import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { fetchMe } from 'store/slices/meSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import Header from './header'
import Sidebar from './sidebar'
import Breadcrumb from './components/Breadcrumb'
import { fetchTeacherStats } from 'store/slices/teacherStatsSlice'
import { fetchCoordinatorStats } from 'store/slices/coordinatorStatsSlice'
import BreadcrumbWatcher from './components/BreadcrumbWatcher'
import routesConfig from './routesConfig'
import { RouteConfig } from 'pages/auth/routesConfig'

interface LayoutProps {
  userRole: string
}

const Layout: React.FC<LayoutProps> = ({ userRole }) => {
  const dispatch = useDispatch<AppDispatch>()

  const is_profile_completed = useSelector(
    (state: RootState) => state.me?.me?.data?.is_profile_completed ?? false
  )
  const { competitionYear } = useSelector((state: RootState) => state.competitionYearNavbar)

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchMe())
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    const fetchData = async () => {
      if (is_profile_completed === true) {
        if (userRole === 'teacher') {
          dispatch(fetchTeacherStats())
        } else if (userRole === 'coordinator' && competitionYear) {
          dispatch(fetchCoordinatorStats({ competition_year_id: competitionYear?.id.toString() }))
        }
      }
    }
    fetchData()
  }, [dispatch, userRole, is_profile_completed, competitionYear])

  
  const extractRoutes = (routes: RouteConfig[]): RouteConfig[] => {
    const result: RouteConfig[] = []
  
    const processRoutes = (routes: RouteConfig[], basePath: string = '') => {
      routes.forEach((route) => {
        const fullPath = basePath? `${basePath}/${route.path}` : route.path
        result.push({ path: fullPath, name: route.name, label: route.label })
  
        if (route.children) {
          processRoutes(route.children, fullPath)
        }
      })
    }
  
    processRoutes(routes)
    return result
  }
  
  return (
    <>
      <BreadcrumbWatcher />
      <div className="h-screen">
        <div className="size-full bg-natural_fill_normal_hover_pressed_active flex flex-col">
          <Header />

          <div className="flex-grow flex flex-row mx-2 pb-2 md:pb-5 md:mx-10 gap-[26px]">
            <Sidebar userRole={userRole} routes={routesConfig} />

            <div className="h-full w-full flex flex-col">
              <Breadcrumb breadcrumbs={extractRoutes(routesConfig)} />
              <main className="relative flex-grow py-4 px-4 md:px-10 md:py-12 rounded-[22px] shadow-xl bg-white" id="main-layout-container">
                <Outlet />
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout
