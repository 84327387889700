import React from 'react'
import ButtonVariant from 'components/button-variant/button'
import ArrowIcon from 'assets/icons/ArrowIcon'
import { Trans } from '@lingui/macro'

interface FormNavigationBtnsProps {
  isFirstStep: boolean
  isLastStep: boolean
  onPrev: () => void
  onNext: () => void
  onSaveDraft: () => void
}

const FormNavigationBtns: React.FC<FormNavigationBtnsProps> = ({
  isFirstStep,
  isLastStep,
  onPrev,
  onNext,
  onSaveDraft
}) => {
  return (
    <div className="flex flex-wrap justify-between items-center gap-2">
      <div className='w-fit'>
        <ButtonVariant
          variant="bordered"
          onClick={onPrev}
          disabled={isFirstStep}
          iconStart={<ArrowIcon fill="currentColor" width="24" height="24" />}
          iconRotate
        >
          <p>
            <Trans>الخلف</Trans>
          </p>
        </ButtonVariant>
      </div>

        {isLastStep && (
      <div className="flex md:flex-1 justify-end">
          <div>
          <ButtonVariant variant="bordered" onClick={onSaveDraft}>
            <p>
              <Trans>حفظ كمسودة</Trans>
            </p>
          </ButtonVariant>
          </div>
      </div>
        )}

      <div className='w-full md:w-fit'>
        <ButtonVariant
          variant="secondary"
          onClick={onNext}
          iconEnd={<ArrowIcon fill="currentColor" width="24" height="24" />}
          iconRotate
          className='w-full md:w-fit'
        >
          {isLastStep ? (
            <p>
              <Trans>تقديم المشاركة</Trans>
            </p>
          ) : (
            <p>
              <Trans>التالي</Trans>
            </p>
          )}
        </ButtonVariant>
      </div>
    </div>
  )
}

export default FormNavigationBtns
