import React from 'react'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import UserLogin from '../forms/user-login'
import { useNavigate } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { msg } from '@lingui/macro'
import Link from 'components/base/link'
const LoginModal: React.FC<{
  handleOTPModalDisplay: (shouldShow: boolean) => void
  handleInactiveModalDisplay: (shouldShow: boolean) => void
  handleResetProcessModalDisplay: (shouldShow: boolean) => void
}> = ({ handleOTPModalDisplay, handleInactiveModalDisplay, handleResetProcessModalDisplay }) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/register')
  }

  return (
    <ModalLayout
      showBackButton={false}
      accountPageStyle
      header={
        <ModalHeader
          headerText={msg`تسجيل الدخول`}
          subheaderText={<Trans>أهلًا بك مجددًا</Trans>}
        />
      }
      body={
        <UserLogin
          handleResetProcessModalDisplay={handleResetProcessModalDisplay}
          handleInactiveModalDisplay={handleInactiveModalDisplay}
          handleOTPModalDisplay={handleOTPModalDisplay}
        />
      }
      footer={
        <div className="mx-auto flex items-center justify-center">
          <p className="mr-1 ml-1 mb-3">
            <Trans>ليس لديك حساب ؟</Trans>
          </p>
          <Link
            placeholder={msg`سجل من هنا`}
            className={'font-label-h5-light-ar'}
            onClick={handleClick}
          />
        </div>
      }
    />
  )
}

export default LoginModal
